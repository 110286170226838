import { combineReducers } from 'redux';
import AuthUserReducer from './AuthUserReducer';
import ContactReducer from './ContactReducer';
import AppSettingsReducer from './AppSettingsReducer';
import UserSettingsReducer from './UserSettingsReducer';
import MenuListReducer from './MenuListReducer';
import AuthReducer from './auth';
import FormInfoReducer from './FormInfoReducer';
import UserMasterReducer from './master/UserMasterReducer';
import SettingMasterReducer from './master/SettingMasterReducer';
import DashboardReducer from './DashboardReducer';
import DesignationMasterReducer from './master/DesignationMasterReducer';
import DepartmentMasterReducer from './master/DepartmentMasterReducer';
import AreaMasterReducer from './master/AreaMasterReducer';
import WardMasterReducer from './master/WardMasterReducer';
import SchemeTypeMasterReducer from './master/SchemeTypeMasterReducer';
import ArchitectReducer from './master/ArchitectMasterReducer';
import DeveloperMasterReducer from './master/DeveloperMasterReducer';
import LandTypeMasterReducer from './master/LandTypeMasterReducer';
import AnnexureMasterReducer from './master/AnnexureMasterReducer';
import SchemeMasterReducer from './master/SchemeMasterReducer';
import SchemeListReducer from './SchemeReducer/SchemeListReducer';
import LegalReducer from './LegalReducer/LegalReducer';
import form14Part1Reducer from './DeputyCollectorReducer/form14Part1Reducer/form14part1Reducer';
import form14Part2Reducer from './DeputyCollectorReducer/form14Part2Reducer/form14part2Reducer';
import LegalHeirReducer from './DeputyCollectorReducer/legalHeirReducer/legalHeirReducer';
import register13FormReducer from './DeputyCollectorReducer/register13formReducer/registerForm13Reducer';
import registerAnnexure2Reducer from './DeputyCollectorReducer/registerAnnexure2Reducer/registerAnnexure2Reducer';
import regNoc144Reducer from './DeputyCollectorReducer/regNoc144Reducer/regnoc144Reducer';
import registerSection33Reducer from './DeputyCollectorReducer/registerSection33Reducer/registerSection33Reducer';
import registerAgreementReducer from './DeputyCollectorReducer/registerAgreementReducer/registerAnnexure2Reducer';
import estateDeptForm1Reducer from './EstateDepartmentReducer/form1Reducer';
import estateDeptForm2Reducer from './EstateDepartmentReducer/form2Reducer.';
import slumActReducer from './master/SlumActReducer';
import advocateReducer from './master/AdvocateMasterReducer';
import listOfApplicationReducer from './TransferOfTenement/listOfApplicationReducer';
import paidRehabilitationReducer from './paidRehabilitationReducer/paidRehabReducer';
import applicationReportReducer from './TransferOfTenement/ApplicationReportReducer';
import ReasonMasterReducer from './master/ApplicationReasonMasterReducer';
import HolidayMasterReducer from './master/HolidayMasterReducer';
import CalendarReducer from './CalendarReducer';
import CourtTypeMasterReducer from './master/CourtTypeMasterReducer';
import jvSchemeReducer from './master/JVSchemeMasterReducer';

const reducers = combineReducers({
	authUser: AuthUserReducer,
	settings: AppSettingsReducer,
	ContactReducer: ContactReducer,
	UserSettingsReducer: UserSettingsReducer,
	menuListReducer: MenuListReducer,
	authReducer: AuthReducer,
	formInfoReducer: FormInfoReducer,
	userMasterReducer: UserMasterReducer,
	settingMasterReducer: SettingMasterReducer,
	dashboardReducer: DashboardReducer,
	designationMasterReducer: DesignationMasterReducer,
	departmentMasterReducer: DepartmentMasterReducer,
	areaMasterReducer: AreaMasterReducer,
	wardMasterReducer: WardMasterReducer,
	schemeTypeMasterReducer: SchemeTypeMasterReducer,
	architectReducer: ArchitectReducer,
	developerMasterReducer: DeveloperMasterReducer,
	landTypeMasterReducer: LandTypeMasterReducer,
	annexureMasterReducer: AnnexureMasterReducer,
	schemeMasterReducer: SchemeMasterReducer,
	schemeListReducer: SchemeListReducer,
	legalReducer: LegalReducer,
	legalHeirReducer: LegalHeirReducer,
	form14Part1Reducer: form14Part1Reducer,
	form14Part2Reducer: form14Part2Reducer,
	register13FormReducer: register13FormReducer,
	registerAnnexure2Reducer: registerAnnexure2Reducer,
	regNoc144Reducer: regNoc144Reducer,
	registerSection33Reducer: registerSection33Reducer,
	registerAgreementReducer: registerAgreementReducer,
	estateDeptForm1Reducer: estateDeptForm1Reducer,
	estateDeptForm2Reducer: estateDeptForm2Reducer,
	slumActReducer: slumActReducer,
	advocateReducer: advocateReducer,
	listOfApplicationReducer: listOfApplicationReducer,
	applicationReportReducer: applicationReportReducer,
	paidRehabilitationReducer: paidRehabilitationReducer,
	reasonMasterReducer: ReasonMasterReducer,
	holidayMasterReducer: HolidayMasterReducer,
	calendarReducer: CalendarReducer,
	courtTypeMasterReducer: CourtTypeMasterReducer,
	jvSchemeReducer : jvSchemeReducer,
});

export default reducers;