/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';

//  Dashboard Acceptance
const AsyncAcceptanceDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/AcceptanceDepDashboard"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

//  Dashboard Eng
const AsyncEnggDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/EnggDepDashboard"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

//  Dashboard CTSO
const AsyncCtsoDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/CtsoDepDashboard"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

//  Dashboard Deputy
const AsyncDeputyDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/DeputyDepDashboard"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

//  Dashboard Cooperation
const AsyncCooperationDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/CooperationDepDashboard"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

//  Dashboard Town Planning
const AsyncTownPlanDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/TownPlanDepDashboard"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Legal Department Dashboard
const AsyncLegalDepDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/LegalDeptDashboard/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Estate Department Dashboard
const AsyncEstateDepDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/EstateDepDashboard/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Paid Rehab Department Dashboard
const AsyncPaidRehabDepDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/PaidRehabDepDashboard/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// CA Department Dashboard
const AsyncCADepDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/CA_DepDashboard/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncCEODyDashboardComponent = Loadable({
	loader: () => import("views/Dashboard1/DY_132_dashboard/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncWelcomeComponent = Loadable({
	loader: () => import("views/Welcome"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Master user //
const AsyncUserMasterComponent = Loadable({
	loader: () => import("views/Master/UserMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("views/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("views/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
	loader: () => import("views/Error/403"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("views/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
	loader: () => import("views/SampleForm"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncFormInformationComponent = Loadable({
	loader: () => import("views/Master/FormInformation/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSettingComponent = Loadable({
	loader: () => import("views/Master/Setting/"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncDesignationComponent = Loadable({
	loader: () => import("views/Master/DesignationMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncDepartmentComponent = Loadable({
	loader: () => import("views/Master/DepartmentMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncAreaComponent = Loadable({
	loader: () => import("views/Master/AreaMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncWardComponent = Loadable({
	loader: () => import("views/Master/WardMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSchemeTypeComponent = Loadable({
	loader: () => import("views/Master/SchemeTypeMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncArchitectComponent = Loadable({
	loader: () => import("views/Master/ArchitectMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncDeveloperComponent = Loadable({
	loader: () => import("views/Master/DeveloperMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncLandTypeComponent = Loadable({
	loader: () => import("views/Master/LandTypeMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncAnnexureComponent = Loadable({
	loader: () => import("views/Master/AnnexureMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});



const AsyncSchemeMasterComponent = Loadable({
	loader: () => import("views/Master/SchemeMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSchemeListComponent = Loadable({
	loader: () => import("views/Schemes/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSchemeDetails = Loadable({
	loader: () => import("views/Schemes/SchemeDetails"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncLegalCaseComponent = Loadable({
	loader: () => import("views/LegalDepartment/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncLegalHeirComponent = Loadable({
	loader: () => import("views/DeputyCollector/LegalHeir/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncRegister13Component = Loadable({
	loader: () => import("views/DeputyCollector/registerOfSection13(2)/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncForm14Part1Component = Loadable({
	loader: () => import("views/DeputyCollector/Form14(1)part(1)/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncForm14Part2Component = Loadable({
	loader: () => import("views/DeputyCollector/Form14(1)part(2)/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncRegisterAnnexure2Component = Loadable({
	loader: () => import("views/DeputyCollector/RegisterOfAnnexure-2/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncRegisterSection33Component = Loadable({
	loader: () => import("views/DeputyCollector/RegisterSection33/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncRegisterOfAgreementComponent = Loadable({
	loader: () => import("views/DeputyCollector/RegisterOfAgreement/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncPaidRehabilitationComponent = Loadable({
	loader: () => import("views/DeputyCollector/PaidRehabilitation/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const AsyncEstateForm1Component = Loadable({
	loader: () => import("views/EstateDepartment/Form1/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncEstateForm2Component = Loadable({
	loader: () => import("views/EstateDepartment/Form2/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncApplicationFormComponent = Loadable({
	loader: () => import("views/TransferOfTenement/listOfApplication/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncApplicationReportComponent = Loadable({
	loader: () => import("views/TransferOfTenement/applicationReport/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const AsyncSlumActComponent = Loadable({
	loader: () => import("views/Master/SlumOfAct/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncAdvocateComponent = Loadable({
	loader: () => import("views/Master/AdvocateCourtMaster/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const AsyncPaidRehabComponent = Loadable({
	loader: () => import("views/paidRehabilitaion/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncApplicationReasonComponent = Loadable({
	loader: () => import("views/Master/ApplicationReasonMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncHolidayComponent = Loadable({
	loader: () => import("views/Master/HolidayMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncCalendarComponent = Loadable({
	loader: () => import("views/Calendar/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncCourtTypeComponent = Loadable({
	loader: () => import("views/Master/CourtTypeMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncJVSchemeComponent = Loadable({
	loader: () => import("views/Master/jvSchemeMaster/index"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

export {
	AsyncAcceptanceDashboardComponent,
	AsyncEnggDashboardComponent,
	AsyncCtsoDashboardComponent,
	AsyncDeputyDashboardComponent,
	AsyncCooperationDashboardComponent,
	AsyncTownPlanDashboardComponent,
	AsyncLegalDepDashboardComponent,
	AsyncEstateDepDashboardComponent,
	AsyncPaidRehabDepDashboardComponent,
	AsyncCADepDashboardComponent,
	AsyncCEODyDashboardComponent,

	AsyncUserMasterComponent,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncErrorPage403Component,
	AsyncSampleFormComponent,
	AsyncFormInformationComponent,
	AsyncSettingComponent,
	AsyncWelcomeComponent,
	AsyncDesignationComponent,
	AsyncDepartmentComponent,
	AsyncAreaComponent,
	AsyncWardComponent,
	AsyncSchemeTypeComponent,
	AsyncArchitectComponent,
	AsyncDeveloperComponent,
	AsyncLandTypeComponent,
	AsyncAnnexureComponent,
	AsyncSchemeListComponent,
	AsyncSchemeMasterComponent,
	AsyncSchemeDetails,
	AsyncLegalCaseComponent,
	AsyncLegalHeirComponent,
	AsyncRegister13Component,
	AsyncForm14Part1Component,
	AsyncForm14Part2Component,
	AsyncRegisterAnnexure2Component,
	AsyncRegisterSection33Component,
	AsyncRegisterOfAgreementComponent,
	AsyncPaidRehabilitationComponent,
	AsyncEstateForm1Component,
	AsyncEstateForm2Component,
	AsyncApplicationFormComponent,
	AsyncApplicationReportComponent,
	AsyncSlumActComponent,
	AsyncAdvocateComponent,
	AsyncPaidRehabComponent,
	AsyncApplicationReasonComponent,
	AsyncHolidayComponent,
	AsyncCalendarComponent,
	AsyncCourtTypeComponent,
	AsyncJVSchemeComponent,
};