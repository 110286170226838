const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: [],
    activeSlumData: [],
    activeAdvocateList: [],
    acceptedSchemeData: [],
    jvSchemeData : [],
    acceptedTotal: 0,
}

const LegalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CASE_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                totalRecords: action.total
            };

        case "GET_WARD_DATA":
            return {
                ...state,
                activeWardData: action.data,
            };

        case "GET_SCHEME_TYPE_DATA":
            return {
                ...state,
                schemeTypeData: action.data,
            };

        case "GET_ACCEPTED_SCHEME_DATA":
            return {
                ...state,
                acceptedTotal: action.acceptedTotal,
                acceptedSchemeData: action.data,
            };
        case "GET_ACTIVE_SLUM_LIST":
            return {
                ...state,
                activeSlumData: action.data,
            };

        case "GET_ACTIVE_ADVOCATE_LIST":
            return {
                ...state,
                activeAdvocateList: action.data,
            };

        case "GET_REASON_OF_APPLICATION":
            return {
                ...state,
                applicationReasonData: action.data
            };

        case 'GET_JV_SCHEME_LIST':
            return {
                ...state,
                jvSchemeData: action.data,
            };
        default:
            return { ...state }
    }
}
export default LegalReducer
